export default {

    activeZone: null,
    showConfirm: false,
    started: false,
    finished: false,
    properyAlert: null,
    adviceKey: null,
    restored: false,
    cpo: null,
    data: {
        station: null,
        connected: null,
        checkconnect: null,
        connecttype: null,
        linkedperson: null,
        linkedleaseplan: null,
        provider: null,
        provider_connecttype: null,
        location: null,
        property: null,
        power: null,
        amps: null,
        chargeathome: false,
        ampsAvailable: 0,
        absence: false,
        approval: false,
        municipality: false,
        speed2080: 0,
        speed0100: 0,
    },

    init() {
        this.restore();

        this.checkCPO();

        window.addEventListener('lpFinished', (e) => {
            console.log(e.detail);
        });

        this.$nextTick(() => {
            if (!this.restored) {
                this.activeZone = 'intro';
            }
        });

        this.$watch('data.station', val => {
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if (val == 'yes') {
                this.scrollTo(this.$refs.connected);
            } else {
                this.scrollTo(this.$refs.homecharge);
            }

            if (val) {
                this.store();

                this.data.connected = null;
                this.data.checkconnect = null;
                this.data.connecttype = null;
                this.data.linkedperson = null;
                this.data.linkedleaseplan = null;
                this.data.provider = null;
                this.data.provider_connecttype = null;
                this.data.location = null;
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
                this.data.chargeathome = null;
            }
        });

        this.$watch('data.connected', val => {
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if (val) {
                this.store();

                if (val == 'yes') {
                    this.scrollTo(this.$refs.linkedperson);
                } else {
                    this.scrollTo(this.$refs.checkconnect);
                }

                this.data.checkconnect = null;
                this.data.connecttype = null;
                this.data.linkedperson = null;
                this.data.linkedleaseplan = null;
                this.data.provider = null;
                this.data.provider_connecttype = null;
                this.data.location = null;
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.checkconnect', val => {
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if (val) {
                this.store();

                if (val == 'no_connect') {
                    this.$nextTick(() => {
                        this.showConfirm = true;
                    });
                } else {
                    this.scrollTo(this.$refs.connecttype);
                }

                this.data.connecttype = null;
                this.data.linkedperson = null;
                this.data.linkedleaseplan = null;
                this.data.provider = null;
                this.data.provider_connecttype = null;
                this.data.location = null;
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.connecttype', val => {
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if (val) {
                this.store();

                if (val == 'smartcable') {
                    this.$nextTick(() => {
                        this.showConfirm = true;
                    });
                } else {
                    this.scrollTo(this.$refs.location);
                }

                this.data.linkedperson = null;
                this.data.linkedleaseplan = null;
                this.data.provider = null;
                this.data.provider_connecttype = null;
                this.data.location = null;
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.linkedperson', val => {
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if (val) {
                this.store();

                if (val == 'other_person') {
                    this.$nextTick(() => {
                        this.showConfirm = true;
                    });
                } else {
                    this.scrollTo(this.$refs.linkedleaseplan);
                }

                this.data.linkedleaseplan = null;
                this.data.provider = null;
                this.data.provider_connecttype = null;
                this.data.location = null;
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.linkedleaseplan', val => {
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if (val) {
                this.store();

                this.data.provider = null;

                if (val == 'yes_lp') {
                    this.$nextTick(() => {
                        this.showConfirm = true;
                    });
                } else {
                    this.scrollTo(this.$refs.provider);
                }

                this.data.provider_connecttype = null;
                this.data.location = null;
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.provider', val => {
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if (val) {
                if (val != 'other_provider') {
                    this.$nextTick(() => {
                        this.showConfirm = true;

                        this.scrollTo(this.$refs.confirm);
                    });
                } else {
                    this.scrollTo(this.$refs.provider_connecttype);
                }
                this.store();

                this.data.provider_connecttype = null;
                this.data.location = null;
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.provider_connecttype', val => {
            this.showConfirm = true;
            this.started = true;
            this.finished = false;

            this.scrollTo(this.$refs.confirm);

            if (val) {
                this.store();

                this.data.location = null;
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.location', val => {
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if(val == 'public') {
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            } else {
                this.scrollTo(this.$refs.property);
            }

            if (val) {
                this.store();

                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.property', val => {
            this.scrollTo(this.$refs.power);
            this.showConfirm = false;
            this.started = true;
            this.finished = false;

            if (val) {
                this.store();

                this.data.power = null;
                this.data.amps = null;
            }
        });

        this.$watch('data.power', val => {
            this.scrollTo(this.$refs.amps);
            this.showConfirm = false;
            this.started = true;
            this.finished = false;
            this.data.amps = null;

            if (val) {
                this.store();

                this.data.amps = null;
            }
        });

        this.$watch('data.amps', val => {
            this.scrollTo(this.$refs.confirm);
            this.started = true;
            this.finished = false;

            if (val) {
                this.store();

                this.showConfirm = true;
            }
        });

        this.$watch('data.chargeathome', val => {
            this.started = true;
            // this.finished = false;

            if (val) {
                this.store();

                this.scrollTo(this.$refs.final);
            }
        });

        this.$watch('showConfirm', val => {
            this.$nextTick(() => {
                if (val === true) {
                    this.scrollTo(this.$refs.confirm);
                }
            });
        });

        this.$watch('finished', (val, oldVal) => {
            if (!val) {
                this.adviceKey = null;

                window.history.pushState({}, null, '/' + window.language);

                return;
            }
            this.$nextTick(() => {
                this.calculateVerdict();
                this.calculateAmpsAvailable();

                if (val === true) {
                    this.store();
                }

                setTimeout(() => {
                    this.scrollTo(this.$refs.verdict, false);
                }, 100);

                window.history.pushState({}, null, '/' + window.language + '/results');
            });
        });
    },

    calculateVerdict() {
        // 1.0
        if ([ 'garage', 'driveway' ].includes(this.data.location) && [ 'my' ].includes(this.data.property) && [ '1-phase-230-plus-n', '1-phase-400-plus-n', '3-phase-400-plus-n' ].includes(this.data.power)) {
            this.adviceKey = 'advice1';
        }
        if ([ 'garage', 'driveway' ].includes(this.data.location) && [ 'my' ].includes(this.data.property) && [ '2-phase-230', '3-phase-230' ].includes(this.data.power)) {
            this.adviceKey = 'advice2';
            this.data.absence = true;
        }
        if ([ 'garage', 'driveway' ].includes(this.data.location) && [ 'rent', 'assoc' ].includes(this.data.property) && [ '1-phase-230-plus-n', '1-phase-400-plus-n', '3-phase-400-plus-n' ].includes(this.data.power)) {
            this.adviceKey = 'advice3';
            this.data.approval = true;
        }
        if ([ 'garage', 'driveway' ].includes(this.data.location) && [ 'rent', 'assoc' ].includes(this.data.property) && [ '2-phase-230', '3-phase-230' ].includes(this.data.power)) {
            this.adviceKey = 'advice4';
            this.data.absence = true;
            this.data.approval = true;
        }
        if ([ 'non-adjacent' ].includes(this.data.location) && [ 'rent', 'assoc' ].includes(this.data.property) && [ '1-phase-230-plus-n', '1-phase-400-plus-n', '3-phase-400-plus-n' ].includes(this.data.power)) {
            this.adviceKey = 'advice5';
            this.data.approval = true;
            this.data.municipality = true;
        }
        if ([ 'non-adjacent' ].includes(this.data.location) && [ 'rent', 'assoc' ].includes(this.data.property) && [ '2-phase-230', '3-phase-230' ].includes(this.data.power)) {
            this.adviceKey = 'advice6';
            this.data.absence = true;
            this.data.approval = true;
            this.data.municipality = true;
        }
        if ([ 'non-adjacent' ].includes(this.data.location) && [ 'my' ].includes(this.data.property) && [ '1-phase-230-plus-n', '1-phase-400-plus-n', '3-phase-400-plus-n' ].includes(this.data.power)) {
            this.adviceKey = 'advice7';
        }
        if ([ 'non-adjacent' ].includes(this.data.location) && [ 'my' ].includes(this.data.property) && [ '2-phase-230', '3-phase-230' ].includes(this.data.power)) {
            this.adviceKey = 'advice8';
            this.data.absence = true;
        }

        // 2.0
        if ([ 'yes' ].includes(this.data.station) && [ 'no' ].includes(this.data.connected) && [ 'no_connect' ].includes(this.data.checkconnect)) {
            this.adviceKey = 'advice9';
        }
        if ([ 'yes' ].includes(this.data.station) && [ 'yes' ].includes(this.data.connected) && [ 'other_person' ].includes(this.data.linkedperson)) {
            this.adviceKey = 'advice10';
        }
        if ([ 'yes' ].includes(this.data.station) && [ 'yes' ].includes(this.data.connected) && [ 'me' ].includes(this.data.linkedperson) && [ 'yes_lp' ].includes(this.data.linkedleaseplan)) {
            this.adviceKey = 'advice11';
        }
        if ([ 'yes' ].includes(this.data.station) && [ 'yes' ].includes(this.data.connected) && [ 'me' ].includes(this.data.linkedperson) && [ 'no_lp' ].includes(this.data.linkedleaseplan) && [ 'bluecorner', 'shell' ].includes(this.data.provider)) {
            this.adviceKey = 'advice12';
        }
        if ([ 'yes' ].includes(this.data.station) && [ 'no' ].includes(this.data.connected) && [ 'yes_connect' ].includes(this.data.checkconnect) && [ 'smartcable' ].includes(this.data.connecttype)) {
            this.adviceKey = 'advice14';
        }
        if ([ 'yes' ].includes(this.data.station) && [ 'yes' ].includes(this.data.connected) && [ 'me' ].includes(this.data.linkedperson) && [ 'no_lp' ].includes(this.data.linkedleaseplan) && [ 'other_provider' ].includes(this.data.provider) && [ 'wallbox' ].includes(this.data.provider_connecttype)) {
            this.adviceKey = 'advice15';
        }
        if ([ 'yes' ].includes(this.data.station) && [ 'yes' ].includes(this.data.connected) && [ 'me' ].includes(this.data.linkedperson) && [ 'no_lp' ].includes(this.data.linkedleaseplan) && [ 'other_provider' ].includes(this.data.provider) && [ 'smartcable' ].includes(this.data.provider_connecttype)) {
            this.adviceKey = 'advice16';
        }

        // console.log(this.adviceKey);
    },

    calculateAmpsAvailable() {
        const ampValue = parseInt(this.data.amps);
        let value = 0;

        if ([ '1-phase-230-plus-n' ].includes(this.data.power)) {
            value = (ampValue*230);
        }

        if ([ '1-phase-400-plus-n' ].includes(this.data.power)) {
            value = (ampValue*400);
        }

        if ([ '2-phase-230' ].includes(this.data.power)) {
            value = (ampValue*230) * Math.sqrt(2);
        }

        if ([ '3-phase-230' ].includes(this.data.power)) {
            value = (ampValue*230) * Math.sqrt(3);
        }

        if ([ '3-phase-400-plus-n' ].includes(this.data.power)) {
            value = (ampValue*400) * Math.sqrt(3);
        }

        this.data.ampsAvailable = (value / 1000).toFixed(1);

        switch (this.data.ampsAvailable) {
            case '5.2':
                this.data.speed2080 = '7h27';
                this.data.speed0100 = '12h29m';
                break;
            case '6.4':
                this.data.speed2080 = '6h02m';
                this.data.speed0100 = '10h06m';
                break;
            case '7.4':
                this.data.speed2080 = '5h15m';
                this.data.speed0100 = '8h45m';
                break;
            case '8.1':
                this.data.speed2080 = '4h48m';
                this.data.speed0100 = '8h';
                break;
            case '9.2':
                this.data.speed2080 = '4h10m';
                this.data.speed0100 = '7h';
                break;
            case '10.0':
                this.data.speed2080 = '3h45m';
                this.data.speed0100 = '6h28m';
                break;
            case '10.4':
                this.data.speed2080 = '3h44m';
                this.data.speed0100 = '6h13m';
                break;
            default:
                // 11 or more
                this.data.speed2080 = '3h35m';
                this.data.speed0100 = '5h50m';
                break;
        }

        if (window.language == 'nl') {
            this.data.speed2080 = this.data.speed2080.replace('h', 'u');
            this.data.speed0100 = this.data.speed0100.replace('h', 'u');
        }
    },

    restore() {
        const dataString = localStorage.getItem('lpData');

        if (dataString) {
            const dataObj = JSON.parse(dataString);

            // if (!dataObj.amps) {
                //     return;
                // }

                this.data = dataObj;
                this.restored = true;

            this.$nextTick(() => {
                this.finished = false;
                this.started = true;

                if (dataObj.amps) {
                    this.showConfirm = true;
                    // this.finished = true;
                } else if (dataObj.power) {
                    this.scrollTo(this.$refs.amps);
                } else if (dataObj.property) {
                    this.scrollTo(this.$refs.power);
                } else if (dataObj.location) {
                    this.scrollTo(this.$refs.property);
                } else if (dataObj.provider_connecttype) {
                    this.showConfirm = true;
                    this.scrollTo(this.$refs.provider_connecttype);
                } else if (dataObj.provider) {
                    if (dataObj.provider != 'other_provider') {
                        this.finished = true;
                    }
                    this.scrollTo(this.$refs.provider);
                } else if (dataObj.linkedleaseplan) {
                    if (dataObj.linkedleaseplan == 'yes_lp') {
                        this.showConfirm = true;
                    }
                    this.scrollTo(this.$refs.linkedleaseplan);
                } else if (dataObj.linkedperson) {
                    if (dataObj.linkedperson == 'other_person') {
                        this.showConfirm = true;
                    }
                    this.scrollTo(this.$refs.linkedperson);
                } else if (dataObj.connecttype) {
                    if (dataObj.connecttype == 'smartcable') {
                        this.showConfirm = true;
                    }
                    this.scrollTo(this.$refs.connecttype);
                } else if (dataObj.checkconnect) {
                    if (dataObj.checkconnect == 'no_connect') {
                        this.showConfirm = true;
                    }
                    this.scrollTo(this.$refs.checkconnect);
                } else if (dataObj.connected) {
                    this.scrollTo(this.$refs.connected);
                } else {
                    this.scrollTo(this.$refs.station);
                }
            });
        }
    },

    store() {
        localStorage.setItem('lpData', JSON.stringify(this.data));

        if (!this.restored) {
            const customevent = new CustomEvent('lpFinished', {
                bubbles: true,
                detail: this.data
            });

            window.dispatchEvent(customevent);
        }
    },

    reset() {
        this.data.station = null;
        this.data.connected = null;
        this.data.checkconnect = null;
        this.data.connecttype = null;
        this.data.linkedperson = null;
        this.data.linkedleaseplan = null;
        this.data.provider = null;
        this.data.location = null;
        this.data.property = null;
        this.data.power = null;
        this.data.amps = null;
        this.data.ampsAvailable = null;
        this.data.absence = false;
        this.data.approval = false;
        this.data.municipality = false;
        this.data.speed2080 = null;
        this.data.speed0100 = null;
        this.finished = false;
        this.restored = false;
        this.started = false;

        window.history.pushState({}, null, '/' + window.language);

        this.$nextTick(() => {
            this.started = false;
            localStorage.removeItem('lpData');
            this.scrollTo(this.$refs.intro);
        });
    },

    start() {
        this.data.station = null;
        this.data.connected = null;
        this.data.checkconnect = null;
        this.data.connecttype = null;
        this.data.linkedperson = null;
        this.data.linkedleaseplan = null;
        this.data.provider = null;
        this.data.location = null;
        this.data.property = null;
        this.data.power = null;
        this.data.amps = null;
        this.data.ampsAvailable = null;
        this.data.absence = false;
        this.data.approval = false;
        this.data.municipality = false;
        this.data.speed2080 = null;
        this.data.speed0100 = null;
        localStorage.removeItem('lpData');

        this.checkCPO();

        this.started = true;
        this.finished = false;
        this.showConfirm = false;
    },

    finish() {
        this.started = true;
        this.showConfirm = false;
        this.finished = true;
    },

    unfinish() {
        this.started = true;
        this.showConfirm = true;
        this.finished = false;
    },

    scrollTo(el, smooth) {
        let isSmooth = smooth ? 'smooth' : 'auto';

        this.$nextTick(() => {
            const frameH = window.innerHeight;
            const frameW = window.innerWidth;

            let offsetIncrement = frameH * 0.1;

            if (frameW > frameW) {
                offsetIncrement = frameH * 0.2;
            }

            const offset = -Math.abs(this.$refs.stickyregion.getBoundingClientRect().height) - offsetIncrement;
            const y = el.getBoundingClientRect().top + window.pageYOffset + offset;

            window.scrollTo({
                top: y,
                behavior: isSmooth
            });

            this.activeZone = el.getAttribute('x-ref');
        });
    },

    getLabel(key) {
        if (window.appLabels && window.appLabels.hasOwnProperty(key)) {
            return window.appLabels[key];
        }

        return key;
    },

    checkCPO() {
        // check if CPO local storage var is set
        var local_cpo = localStorage.getItem('cpo');
        if (!local_cpo || local_cpo == null) {
            // check if CPO url var is set
            var url_cpo = new URLSearchParams(location.search).get('cpo');
            if (url_cpo == 'bluecorner') {
                this.cpo = 'Blue Corner';
            } else {
                // set fallback to Shell
                this.cpo = 'Shell';
            }
            localStorage.setItem('cpo', this.cpo);
        } else {
            this.cpo = local_cpo;
        }
    },

    intro() {
        localStorage.removeItem('lpData');
        this.scrollTo(this.$refs.intro);

        this.started = false;
        this.showConfirm = false;
        this.finished = false;
        this.restored = false;
    }

};
